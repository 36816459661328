import React from 'react';
import { Icon } from 'semantic-ui-react';
import './styles.css';

export default function Rating (props) {

  function generateStarRating() {
    const stars = Array(5).fill(0);
    stars.fill(1, 0, props.rating);

    return stars.map((element, index) => {
      if (element === 1) {
        return <Icon key={index} name="star" color="red" />;
      }
      return <Icon key={index} name="star outline" color="red" />;
    });
  }

  if (props.ratingCount !== null) {
      return (
        <div>
          {generateStarRating()} ({props.ratingCount})
        </div>
      );
  }

    return <div className="make-inline">{generateStarRating()}</div>;
}
