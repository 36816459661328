import 'whatwg-fetch';
import config from '../../config/config';

export const REQUEST_PRODUCTS = 'REQUEST_PRODUCTS';
export const RECEIVE_PRODUCTS = 'RECEIVE_PRODUCTS';
export const RECEIVE_1PAGE_PRODUCTS = 'RECEIVE_1PAGE_PRODUCTS';
export const PRODUCTS_PAGE_COUNT = 20;
export const SET_TAG_FILTER = 'SET_TAG_FILTER';
export const CLEAR_TAG_FILTER = 'CLEAR_TAG_FILTER';
export const SET_SELLER_FILTER = 'SET_SELLER_FILTER';
export const CLEAR_SELLER_FILTER = 'CLEAR_SELLER_FILTER';
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER';
export const CLEAR_CATEGORY_FILTER = 'CLEAR_CATEGORY_FILTER';
export const SET_DISPLAYORDER_FILTER = 'SET_DISPLAYORDER_FILTER';
export const CLEAR_DISPLAYORDER_FILTER = 'CLEAR_DISPLAYORDER_FILTER';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const SET_SEARCH = 'SET_SEARCH';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';

export const clearProducts = () => ({
  type: CLEAR_PRODUCTS,
});

export const setTagFilter = (tag) => ({
  type: SET_TAG_FILTER,
  tag
})

export const clearTagFilter = () => ({
  type: CLEAR_TAG_FILTER,
})

export const setSellerFilter = (seller) => ({
  type: SET_SELLER_FILTER,
  seller
})

export const clearSellerFilter = () => ({
  type: CLEAR_SELLER_FILTER,
})

export const setCategoryFilter = (category) => ({
  type: SET_CATEGORY_FILTER,
  category
})

export const clearCategoryFilter = () => ({
  type: CLEAR_CATEGORY_FILTER,
})

export const setDisplayOrderFilter = (popularity) => ({
  type: SET_DISPLAYORDER_FILTER,
  popularity
})

export const clearDisplayOrderFilter = () => ({
  type: CLEAR_DISPLAYORDER_FILTER,
})

export const setProdSearch = (search) => ({
  type: SET_SEARCH,
  search,
});

export const clearProdSearch = () => ({
  type: CLEAR_SEARCH,
});

export const requestProducts = (page) => ({
  type: REQUEST_PRODUCTS,
  page
});

export const receiveProducts = (products) => ({
  type: RECEIVE_PRODUCTS,
  products,
});

export const receive1PageProducts = (products) => ({
  type: RECEIVE_1PAGE_PRODUCTS,
  products,
});

export const fetchProductsByCategory = (params = {}) => (dispatch) => {
  dispatch(requestProducts(params.page ?? 1));

  const url = `${config.API_PRODUCTS_URL}?tags=${encodeURIComponent(params.tags ?? '')}&category=${encodeURIComponent(params.category)}&page=${params.page ?? 1}&per_page=${params.per_page ?? PRODUCTS_PAGE_COUNT}`;

  return fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Error fetching products: ${response.statusText}`);
        }
        return response.json();
      })
      .then((json) => {
        dispatch(params.page !== 1 ? receiveProducts(json) : receive1PageProducts(json));
      })
      .catch((error) => {
        console.error(error);
        dispatch(receiveProducts([]));
      });
};


export const fetchProducts = (params = {}) => (dispatch) => {
  dispatch(requestProducts(params.page ?? 1));

  let url;
  if (params && params.id) {
    url = config.API_PRODUCT_URL + String(params.id);
  } else {
    url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  }


  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      if (params.id)
        dispatch(receiveProducts([json]));
      else
        dispatch(params.page !== 1 ? receiveProducts(json) : receive1PageProducts(json));
    })
    .catch(() => {
      dispatch(receiveProducts([]));
    });
};

export const fetchAllProducts = (params = {}) => (dispatch) => {
  dispatch(requestProducts(params.page));

  const url = config.API_PRODUCTS_URL
      + '?'
      + Object.keys(params)
        .map((k) => k + '=' + encodeURIComponent(params[k]))
        .join('&');
  return fetch(url)
    .then((response) => response.json())
    .then((json) => {
      dispatch(params.page === 1 ? receive1PageProducts(json) : receiveProducts(json));
      // рекурсия пока все не вычитаем
      if (json.length === params.per_page) {
        params.page ++;
        dispatch(fetchAllProducts(params));
      }
    })
    .catch(() => {
      dispatch(receiveProducts([]));
    });
};
