import {combineReducers} from 'redux'
import { REQUEST_NEWS, RECEIVE_NEWS, NEWS_PAGE_COUNT} from "./actions";
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_NEWS:
            if (action.page === 1)
                return action.news;
            else
                return mergeObjectArrays(action.news, state);
        default:
            return state;
    }
};

const hasMore = (state = false, action = 0) => {
    switch (action.type) {
        case REQUEST_NEWS:
            return true;
        case RECEIVE_NEWS:
            return action.news.length >= NEWS_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = false, action) => {
    switch (action.type) {
        case REQUEST_NEWS:
            return true;
        case RECEIVE_NEWS:
            return false;
        default:
            return state;
    }
};

export const getNews = (state) => state.items
export const getNewsFetching = (state) => state.isFetching
export const getNewsHasMore = (state) => state.hasMore

export default combineReducers({
    items,
    isFetching,
    hasMore,
});