import React, {useEffect, useState} from 'react';
import './styles.css';
import {useSelector} from "react-redux";

import FilterModal from "../../components/Filtration/filter";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import ProductCard from "../../components/ProductCard/ProductCard";
import {isMobile} from "react-device-detect";

import {getFavoriteCards} from "./reducer";

export default function Favorite(props) {
    const {dispatch} = props;

    const favorites = useSelector((state) => getFavoriteCards(state.favorites))
    const [products, setProducts] = useState([])

    useEffect(() => {
        const arrayCards = [];
        console.log(favorites, 'favorites');
        favorites.forEach((product) => {
            arrayCards.push(
                <ProductCard
                    key={product.id}
                    id={product.id}
                    src={product.src}
                    name={product.name}
                    categories={product.categories}
                    tags={product.categories}
                    seller_company={product.seller_company}
                    seller_company_id={product.seller_company_id}
                />
            )
        })
        console.log(arrayCards, 'arrayCards');
        setProducts(arrayCards);
    }, [favorites]);

    return (
        <div className='favorite-view'>
            <h1 className='favorite-title'>Избранное</h1>
            {isMobile ?
                <div className='favorite-filter-modal'>
                    <FilterModal  isMobile={true}/>
                </div>
                :
                <div className='favorite-filter-dropdown'>
                    <FilterDropdownMenu />
                </div>}
            {products.length > 0 ?
                <div className='favorite-container'>

                    {products}

                </div>
                :
                <p className='favorite-empty-text'>Пока нет избранных продуктов</p>
            }
        </div>
    )
}
