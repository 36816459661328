import { combineReducers } from 'redux';
import {REQUEST_CUSTOMER_OFFERS, RECEIVE_CUSTOMER_OFFERS, RECEIVE_1PAGE_OFFERS, CREATE_CUSTOMER_OFFER, OFFERS_PAGE_COUNT} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const items = (state=[], action) => {
    switch (action.type) {
        case REQUEST_CUSTOMER_OFFERS:
            return state;
        case RECEIVE_CUSTOMER_OFFERS:
            return mergeObjectArrays(action.offers, state);
        case RECEIVE_1PAGE_OFFERS:
            return action.offers;
        case CREATE_CUSTOMER_OFFER:
            return [action.offer, ...state];
        default:
            return state;
    }
};

const hasMore = (state=false, action) => {
    switch (action.type) {
        case REQUEST_CUSTOMER_OFFERS:
            return true;
        case RECEIVE_CUSTOMER_OFFERS:
        case RECEIVE_1PAGE_OFFERS:
            return action.offers.length >= OFFERS_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
    switch (action.type) {
        case REQUEST_CUSTOMER_OFFERS:
            return {fetching: true, page: action.page};
        case RECEIVE_CUSTOMER_OFFERS:
        case RECEIVE_1PAGE_OFFERS:
            return {...state, fetching: false};
        default:
            return state;
    }
};

export const getOffers = (state) => state.items;
export const getOffersFetching = (state) => state.isFetching.fetching && state.isFetching.page === 1;
export const getOffersHasMore = (state) => state.hasMore;

export default combineReducers({
    items,
    hasMore,
    isFetching
});
