import React, { useState } from 'react';
import "./style.css";
import filtrationIcon from "../../icons/filtration-icon.png";
import { Modal, ModalContent, Button } from "semantic-ui-react";
import FilterDropdown from "./menu.js";
import {ReactComponent as CloseIcon} from "../../icons/filter-close-button.svg";
import {useDispatch, useSelector} from "react-redux";
import {getLanguage} from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import {getProducts, getProductsFilter} from "../../views/Products/reducer";
import {
    setCategoryFilter,
    setDisplayOrderFilter,
    setSellerFilter,
    setTagFilter} from "../../views/Products/actions";
import {getCompanies} from "../../views/CongressOnline/reducer";
import {getCategories} from "../../views/Categories/reducer";



function filterReducer(state, action) {
    switch (action.type) {
        case 'close':
            return { open: false };
        case 'open':
            return { open: true, size: action.size };
        default:
            throw new Error('Unsupported action...');
    }
}

const FilterModal = (props) => {

    const {filter, isFilterVisible = false} = props

    const dispatch1 = useDispatch()

    const products = useSelector((state) => getProducts(state.products));
    const companies = useSelector((state) => getCompanies(state.companies));
    const categories = useSelector((state) => getCategories(state.categories));

    const allSellers = products.flatMap(product => product.seller_company);
    const uniqueSellers = Array.from(new Map(allSellers.map(seller => [seller.id, seller])).values());
    const sellerOptions = uniqueSellers.map(seller => ({
        key: seller.id,
        name: seller.name,
        id: seller.id,
    }));

    const filterProducts = useSelector((state) => getProductsFilter(state.products));

    const prodFilter = filter ?? {
        tags: filterProducts.tag ?? [],
        sellers: filterProducts.seller ?? [],
        categories: filterProducts.category ?? [],
        popularities: filterProducts.popularity ?? []
    }

    const allTags = products.flatMap(product => product.tags)
    const filters2 = Array.from(new Map(allTags.filter(cat => cat && cat.id).map(c => [c.id, c])).values());

    const filters3 = sellerOptions;

    const filters4 = categories.map(cat => ({
        name: cat.name,
        key: cat.term_id,
        id: cat.term_id,
    }))
    const filters5 = [
        { key: 1, name: 'По убыванию', id: 1 },
        { key: 2, name: 'По возрастанию', id: 2 }
    ];

    const language = useSelector(getLanguage);
    const t = (key) => {
        const languageString = language[0]+language[1];
        const translation = translations[languageString];
        return translation ? translation[key] || key : key;
    };

    const [state, dispatch] = React.useReducer(filterReducer, {
        open: false,
        size: undefined,
    });
    const [openDropdown, setOpenDropdown] = useState(null);
    const [selectedFilters2, setSelectedFilters2] = useState(prodFilter ? prodFilter.tags : []);
    const [selectedFilters3, setSelectedFilters3] = useState(prodFilter ? prodFilter.sellers : []);
    const [selectedFilters4, setSelectedFilters4] = useState(prodFilter ? prodFilter.categories : []);
    const [selectedFilters5, setSelectedFilters5] = useState(prodFilter ? prodFilter.popularities : []);

    const {isMobile, isOffers, isCompany} = props;

    const { open, size } = state;

    const handleFilterSelection = (selectedFilters, setSelectedFilters, filterType = '', isSingleSelect = false) => (filter) => {
        if (isSingleSelect) {
            if (selectedFilters.includes(filter.id)) {
                setSelectedFilters([]);
                if (filterType === "popularity") {
                    dispatch1(setDisplayOrderFilter([]))
                }
            } else {
                setSelectedFilters([filter.id]);
                if (filterType === "popularity") {
                    dispatch1(setDisplayOrderFilter([filter.id]))
                }
            }
        } else {
            if (selectedFilters.includes(filter.id)) {
                setSelectedFilters(selectedFilters.filter(item => item !== filter.id));
                switch (filterType) {
                    case "animal":
                        dispatch1(setTagFilter(selectedFilters.filter(item => item !== filter.id)));
                        break;
                    case "seller":
                        dispatch1(setSellerFilter(selectedFilters.filter(item => item !== filter.id)));
                        break;
                    case "category":
                        dispatch1(setCategoryFilter(selectedFilters.filter(item => item !== filter.id)));
                        break;
                    default:
                        break;
                }
            } else {
                const newFilters = [...selectedFilters, filter.id];
                setSelectedFilters(newFilters);
                switch (filterType) {
                    case "animal":
                        dispatch1(setTagFilter(newFilters));
                        break;
                    case "seller":
                        dispatch1(setSellerFilter(newFilters));
                        break;
                    case "category":
                        dispatch1(setCategoryFilter(newFilters));
                        break;
                    default:
                        break;
                }
            }
        }


    };

    const toggleDropdown = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdownName);
        }
    };

    function handleRemoveFilters () {
        setSelectedFilters2([]);
        setSelectedFilters3([]);
        setSelectedFilters4([]);
        setSelectedFilters5([]);
        dispatch1(setCategoryFilter([]));
        dispatch1(setSellerFilter([]));
        dispatch1(setTagFilter([]));
        dispatch1(setDisplayOrderFilter([]));
    }

    const mobileStyle = isMobile ? {boxShadow: 'none', position:'absolute',left:'-38px',top:'45px',background:'none', fontSize:'10px',fontWeight:'bold',textAlign:'center'} : { cursor: 'pointer' }

    const allFilters = [...selectedFilters2, ...selectedFilters3, ...selectedFilters4, ...selectedFilters5];
    // console.log(allFilters);
    return (
        isFilterVisible ? <>
                <span id={'all-filter-button'} style={mobileStyle} onClick={() => dispatch({ type: open ? 'close' : 'open', size: 'mini' })}>
                    {isMobile ? (
                        <>
                            <img id={'img-filter'} src={filtrationIcon} alt={''} />
                            <span style={{position: "relative"}}>
                                {t('allFilters')}
                                {allFilters.length > 0 && <div className='filter-bubble'></div>}
                            </span>


                        </>
                    ) : (
                        <>
                            <span>{t('allFilters')}</span>
                            <img id={'img-filter'} src={filtrationIcon} alt={''}/>
                        </>
                    )}
                </span>

            <Modal
                size={size}
                open={open}
                onClose={() => dispatch({ type: 'close' })}
                className="all-filter-modal"
            >
                <Button
                    icon
                    style={{
                        float: 'right',
                        margin: '10px',
                        backgroundColor: 'white',
                    }}
                    onClick={() => dispatch({ type: 'close' })}
                >
                    <CloseIcon/>
                </Button>
                <ModalContent className='filter-modal-content'>
                    <p id={"filter-title-text"}>Фильтры</p>
                    <FilterDropdown
                        title={t('Метки')}
                        filters={filters2}
                        isOpen={openDropdown === 'animalType'}
                        toggleDropdown={() => toggleDropdown('animalType')}
                        handleFilterSelection={handleFilterSelection(selectedFilters2, setSelectedFilters2, 'animal')}
                        selectedFilters={selectedFilters2}
                    />
                    {isCompany && <FilterDropdown
                        title={t('seller')}
                        filters={filters3}
                        isOpen={openDropdown === 'seller'}
                        toggleDropdown={() => toggleDropdown('seller')}
                        handleFilterSelection={handleFilterSelection(selectedFilters3, setSelectedFilters3, 'seller')}
                        selectedFilters={selectedFilters3}
                    />}
                    <FilterDropdown
                        title={t('category')}
                        filters={filters4}
                        isOpen={openDropdown === 'category'}
                        toggleDropdown={() => toggleDropdown('category')}
                        handleFilterSelection={handleFilterSelection(selectedFilters4, setSelectedFilters4, 'category')}
                        selectedFilters={selectedFilters4}
                    />
                   <FilterDropdown
                        title={"Порядок отображения"}
                        filters={filters5}
                        isOpen={openDropdown === 'popularity'}
                        toggleDropdown={() => toggleDropdown('popularity')}
                        handleFilterSelection={handleFilterSelection(selectedFilters5, setSelectedFilters5, 'popularity',true)}
                        selectedFilters={selectedFilters5}
                    />
                    <div className='filter-buttons-container'>
                    <Button
                        fluid
                        id="all-filter-modal-button"
                        onClick={() => dispatch({ type: 'close' })}
                    >
                        Применить
                    </Button>
                    {allFilters.length > 0 &&
                        <Button
                        fluid
                        id="all-filter-modal-button"
                        onClick={() => handleRemoveFilters()}
                        >
                            Убрать фильтры
                        </Button>
                    }
                    </div>
                </ModalContent>
            </Modal>
        </> : null
    );
};

export default FilterModal;

