import {combineReducers} from "redux";
import {REQUEST_FAVORITE_CARDS, RECEIVE_FAVORITE_CARDS, RECEIVE_1PAGE_CARDS, ADD_FAVORITE_CARD, REMOVE_FAVORITE_CARD, CARDS_PAGE_COUNT} from "./actions";


const items = (state = [], action) => {
    switch (action.type) {
        case REQUEST_FAVORITE_CARDS:
            return state;
        case RECEIVE_FAVORITE_CARDS:
            return action.cards;
        case RECEIVE_1PAGE_CARDS:
            return action.cards;
        case ADD_FAVORITE_CARD:
            return [action.card, ...state];
        case REMOVE_FAVORITE_CARD:
            return state.filter((item, index) => index !== action.index);
        default:
            return state;
    }
};

const hasMore = (state = false, action) => {
    switch (action.type) {
        case REQUEST_FAVORITE_CARDS:
            return true;
        case RECEIVE_FAVORITE_CARDS:
        case RECEIVE_1PAGE_CARDS:
            return action.cards.length >= CARDS_PAGE_COUNT;
        default:
            return state;
    }
};

const isFetching = (state = {fetching: false, page: 1}, action) => {
    switch (action.type) {
        case REQUEST_FAVORITE_CARDS:
            return {fetching: true, page: action.page};
        case RECEIVE_FAVORITE_CARDS:
        case RECEIVE_1PAGE_CARDS:
            return {...state, fetching: false};
        default:
            return state;
    }
}

export const getFavoriteCards = (state) => state.items;
export const getFavoriteCardsFetching = (state) => state.isFetching.fetching && state.isFetching.page === 1;
export const getFavoriteCardsHasMore = (state) => state.hasMore;


export default combineReducers({
    items,
    hasMore,
    isFetching
})
