import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {clearProducts, fetchProductsByCategory} from "../../views/Products/actions";
import { getProductsFetching, getProductsHasMore } from "../../views/Products/reducer";
import ProductCard from "../ProductCard/ProductCard";
import { isMobile } from "react-device-detect";
import ProductPager from "../ProductPager";

const ProductLoader = (props) => {
    const { category, loaderText, zeroText } = props;

    const dispatch = useDispatch();
    const products = useSelector(state => state.products.items);
    const hasMore = useSelector(state => getProductsHasMore(state.products));
    const loading = useSelector(state => getProductsFetching(state.products));

    const isTag = category >= 218;
    const isCategory = !isTag;

    const [currentPage, setCurrentPage] = useState(1);

    // Фильтруем продукты по категории или тегам
    const filteredProducts = products.filter(product =>
        product.categories.some(cat => cat.id === Number(category)) ||
        product.tags.some(tag => tag.id === Number(category))
    );

    useEffect(() => {
        setCurrentPage(1);
        dispatch(clearProducts());
        dispatch(fetchProductsByCategory({category, page: 1, per_page: 40 }));
    }, [category, dispatch]);

    const loadMoreProducts = () => {
        const nextPage = currentPage + 1;
        dispatch(fetchProductsByCategory({category, page: nextPage, per_page: 16 }))
            .then(() => setCurrentPage(nextPage));
    };

    return (
        <>
            {filteredProducts.length > 0 ? (
                <ProductPager
                    itemsPerPage={8}
                    dataLength={filteredProducts.length}
                    dataList={filteredProducts.map(product => (
                        <ProductCard
                            key={product.id}
                            id={product.id}
                            src={product.images[0]?.src}
                            name={product.name}
                            categories={product.categories}
                            tags={product.tags}
                            shortDescription={product.short_description}
                            categoryId={product.categories[0]?.id}
                            has_options={product.has_options}
                            virtual={product.virtual}
                            seller_company={product.seller_company.name}
                            seller_company_id={product.seller_company.id}
                            seller_company_logo={product.seller_company.logo}
                        />
                    ))}
                    nextFetch={loadMoreProducts}
                    columns={4}
                    rows={isMobile ? 3 : 2}
                    width={isMobile ? 8 : 4}
                    hasMore={hasMore}
                    isLoading={loading}
                    windowScroll={true}
                />
            ) : (
                <p style={{display:"flex", justifyContent:"center"}}>Пока нет товаров в данном сегменте</p>
            )}
        </>
    );
};

export default ProductLoader;
