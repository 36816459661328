import React, {useEffect} from 'react';
import ReactDOM from "react-dom/client";
import {Provider, useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {HistoryRouter} from "redux-first-history/rr6";
import {PersistGate} from 'redux-persist/integration/react';
import {Loader} from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import translations from '../src/components/LanguageModal/translations';

import {persistor, routerHistory, store} from './configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

import Home from './views/Home';
import Products from './views/Products';
import Categories from './views/Categories';
import Product from './views/Product';
import Cart from './views/Cart';
import Search from './views/Search';
import Company from './views/Company';
import Favorite from './views/Favorite';
import EditProfile from "./views/EditProfile";
import FAQ from './views/FAQ';

import './index.css';

import {closeMenu} from "./components/NavBottomBar/actions";
import {closeSearch} from "./components/NavTopBar/actions";
import {isSearchVisible} from "./components/NavTopBar/reducer";
import {getAuthToken, getUserData, getUserLoggedIn} from "./components/UserLogin/reducer";
import {getLocationData} from "./components/GeoLocation/reducer";
import {useMixpanel} from "react-mixpanel-browser";
import {isMenuVisible} from "./components/NavBottomBar/reducer";
import {isArray} from "lodash";
import Catalog from './views/Catalog'
import animalHusbandry from "./views/AnimalHusbandry";
import CompanyCard, {CongressOnline, CongressOnlineMobile} from "./views/CongressOnline";
import {Gallery} from "./views/Gallery";
import plantProduction from "./views/PlantProduction";
import Technique from "./views/Technique";
import Services from "./views/Services";
import CustomerOffers from "./views/CustomerOffers";
import Blog from "./views/Blog";
import ProductCard from "./views/ProductCard";
import Tags from "./views/Tags";
import ManufacturingProducts from "./views/ManufacturingProducts";
const root = ReactDOM.createRoot(document.getElementById("root"));

console.log(`App "${process.env.REACT_APP_NAME}" version ${process.env.REACT_APP_VERSION} is running in ${process.env.REACT_APP_ENV} mode`)

const withRouter = (Component) => (props) => {

    const router = useSelector((state) => state.router);

    const defaultProps = {
        navigate: useNavigate(),
        dispatch: useDispatch(),
        searchVisible: useSelector((state) => isSearchVisible(state.navtopbar)),
        menuVisible: useSelector((state) => isMenuVisible(state.navbottombar)),
        userData: useSelector((state) => getUserData(state.userLogin)),
        userLocation: useSelector((state) => getLocationData(state.userLocation)),
        isLoggedIn: useSelector((state) => getUserLoggedIn(state.userLogin)),
        token: useSelector((state) => getAuthToken(state.userLogin)),
        mixpanel: useMixpanel(),
        routeAction: router.action,
        location: router.location,
        previousLocations: router.previousLocations,
        isPageReloaded: router.action === 'POP' && isArray(router.previousLocations) && _.size(router.previousLocations) === 1,
    }
    const {menuVisible, searchVisible, dispatch} = defaultProps;

    useEffect(() => {
        if (menuVisible)
            dispatch(closeMenu());

        if (searchVisible)
            dispatch(closeSearch());
    }, []);

    return <Component {...defaultProps}/>;
}

root.render(
  <Provider store={store}>
    <PersistGate loading={<Loader active />} persistor={persistor}>
      <HistoryRouter history={routerHistory}>
        <App>
          <Routes>
              <Route exact path={`/`} Component={withRouter(Home)} />
              <Route path={`/categories/:categId`} Component={withRouter(Categories)} />
              <Route path={`/products/:categId`} Component={withRouter(Tags)} />
              <Route path={`/product/:productId`} Component={withRouter(Product)} />
              <Route path={`/search/:search`} Component={withRouter(Search)} />
              <Route path={`/cart`} Component={withRouter(Cart)} />
              <Route path={'/congress-online/company/:companyId'} Component={withRouter(Company)} />
              <Route path={`/congress-online`} Component={withRouter(CongressOnline)} />
              <Route path={`/congress-online/gallery`} Component={withRouter(Gallery)} />
              <Route path={`/catalog`} Component={withRouter(Catalog)} />
              <Route path={`/catalog/animal-husbandry`} Component={withRouter(animalHusbandry)} />
              <Route path={`/catalog/plant-production`} Component={withRouter(plantProduction)} />
              <Route path={`/technique`} Component={withRouter(Technique)} />
              <Route path={`/services`} Component={withRouter(Services)} />
              <Route path={`/customer-offers`} Component={withRouter(CustomerOffers)} />
              <Route path={`/blog`} Component={withRouter(Blog)} />
              <Route path={`/products/product-card/:productId`} Component={withRouter(ProductCard)} />
              <Route path={`/favorite`} Component={withRouter(Favorite)} />
              <Route path={`/catalog/manufacturing-products`} Component={withRouter(ManufacturingProducts)} />
              <Route path={'/faq'} Component={withRouter(FAQ)} />
          </Routes>
        </App>
      </HistoryRouter>
    </PersistGate>
  </Provider>
);

//registerServiceWorker();

export default withRouter;

