import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { fetchProducts } from '../Products/actions';
import { getProducts, getProductsFetching } from '../Products/reducer';
import ProductDetails from './ProductDetails';
import {useParams} from 'react-router';

export default function Product(props) {

  const {dispatch, userData, userLocation, mixpanel} = props;

  const {productId} = useParams();
  const loading = useSelector( (state) => getProductsFetching(state.products));
  const products =  useSelector( (state) => getProducts(state.products));

  const product = products.find((obj) => obj.id === Number(productID),);

  useEffect(() => {
    readProduct();
    if (mixpanel)
      mixpanel.track('Product Card View', { ...userLocation, ...userData, product });
      window.scrollTo(0, 0);
  }, [productID]);

  const readProduct = () => {
    dispatch(fetchProducts({ id: productId }));
  };

  if (loading) {
    return (<div><Loader active /></div>);
  }

  if (_.isNil(product)) {
    return <p>Продукт не существует</p>;
  }

  return  (<ProductDetails product={product} />);
}


