import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {Loader, Container, Header, Dimmer} from 'semantic-ui-react';

import {
    fetchSearch,
    receiveSearch, SEARCH_CONTEXT_ANIMAL_HUSBANDRY, SEARCH_CONTEXT_ANOTHER_PRODUCTS, SEARCH_CONTEXT_CROP_PRODUCTION,
    SEARCH_CONTEXT_PRODUCTS,
    SEARCH_CONTEXTS,
    SEARCH_PAGE_COUNT
} from './actions';
import {
    getSearchFetching,
    getSearch,
    getHasMore,
    getToContext,
    getFromContext
} from './reducer';
import ProductsList from '../../components/ProductsList';

import {useParams} from 'react-router';

import "./style.css"
import InfiniteView from "../../components/InfiniteView";
import {setWindowScrollPosition} from "../../components/WindowDimensions";
import {getProducts, getProductSearch, getProductsFilter} from "../Products/reducer";
import {fetchCatalogue} from "../Categories/actions";
import {
    fetchProducts,
    setCategoryFilter,
    setDisplayOrderFilter,
    setSellerFilter,
    setTagFilter
} from "../Products/actions";
import {getCategories} from "../Categories/reducer";
import FilterDropdown from "../../components/Filtration/menu";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import FilterModal from "../../components/Filtration/filter";
import {Base64} from "../../App";
import ProductPager from "../../components/ProductPager";
import ProductLoader from "../../components/ProductLoader";
import {isMobile} from "react-device-detect";
import ProductCard from "../../components/ProductCard/ProductCard";


export function isSearchView(pathname) {
    return pathname.indexOf('search') !== -1;
}
export default function Search(props) {

  const {routeAction, location, dispatch, token, userLocation, userData, mixpanel, isPageReloaded} = props;

  const {search} = useParams();

  const loading = useSelector((state) => getSearchFetching(state.search));
  const searchItems = useSelector((state) => getSearch(state.search));
  const hasMore = useSelector((state) => getHasMore(state.search));
  const fromContext = useSelector((state) => getFromContext(state.search));
  const searchContext = useSelector((state) => getToContext(state.search));
  const filter = useSelector((state) => getProductsFilter(state.products));
  const productSearch = useSelector((state) => getProductSearch(state.products));

    useEffect(() => {
        if (filter && routeAction === 'PUSH') {
            dispatch(setTagFilter([]))
            dispatch(setCategoryFilter([]))
            dispatch(setDisplayOrderFilter([]))
            dispatch(setSellerFilter([]))
        }
    }, []);


  const filteredProducts = searchItems.filter(product =>
        product.categories.some(category => category.name === searchContext) && product !== false);
  //
  //   const filteredByName = searchItems.filter(product =>
  //       product.name.toLowerCase().includes(search.toLowerCase())
  //   );
  //
  //   const filteredCatByName = filteredProducts.filter(product =>
  //       product.name.toLowerCase().includes(search.toLowerCase())
  //   );


    const context = SEARCH_CONTEXTS.find(obj => obj.value === fromContext);
  const contextTitle = _.isObject(context) ? context.title : '';
  const title = () => {
      return(
          <>
              <div className='search-result-text'>Результаты поиска</div>
              <FilterDropdownMenu isCompany={true}/>
          </>
      )
  };
  const nothingTitle = () => {
      return(
          <>
            <div className='search-result-text'>По запросу "{search}" {contextTitle} ничего не найдено</div>
            {/*<FilterDropdownMenu/>*/}
          </>
      )
  }

  useEffect(() => {
      if ( routeAction === 'PUSH' || isPageReloaded ) {
              window.scrollTo(0, 0);
              dispatch(receiveSearch(searchContext, 1, []));
              readSearch(1, searchContext);
          }
          if (mixpanel)
              mixpanel.track('Search List View',
                  {...userLocation, ...userData, search, searchContext, fromContext, routeAction, ...location});
  }, [search, searchContext]);

  useEffect(() => {
        if (isPageReloaded) {
            setWindowScrollPosition();
        }
  }, [props]);

  const loadMore = () => readSearch(Math.round(searchItems.length / SEARCH_PAGE_COUNT) + 1, searchContext);

  const readSearch = (page, context)  => {
      const params = {
      search,
      page,
      per_page: SEARCH_PAGE_COUNT,
      order: 'asc',
      orderby: 'id',
  }

    // params['search'] = Base64.encode(JSON.stringify(productSearch));

    dispatch(fetchSearch(token, context, params));



  }

  if (!navigator.onLine)
      return (<Container><p>Нет подключения к Интернету</p></Container>);

  const loaderText = `Ищем '${search}' ${contextTitle}...`;

  if (loading && (searchItems.length === 0 || fromContext !== searchContext)) {
      return (
          <Dimmer active={true} inverted className="page-loader">
              <Loader active>{loaderText}</Loader>
          </Dimmer>
      );
  }



    // console.log(filteredByName, filteredCatByName, searchItems);


    return (
      <div className={"search-page"}>
          {searchItems.length !== 0 || filteredProducts.length !== 0 ? title() : nothingTitle()}
      <ProductPager
          itemsPerPage={8}
          dataLength={searchContext === 'products' ? searchItems.length : filteredProducts.length}
          nextFetch={loadMore}
          columns={4}
          rows={3}
          width={4}
          hasMore={hasMore}
          isLoading={loading}
          windowScroll={false}
          dataList={(
              (searchContext === 'products' ? searchItems : filteredProducts).map(product => (
                  <ProductCard
                      key={product.id}
                      id={product.id}
                      src={product.images[0]?.src}
                      name={product.name}
                      categories={product.categories}
                      tags={product.tags}
                      shortDescription={product.short_description}
                      categoryId={product.categories[0]?.id}
                      has_options={product.has_options}
                      virtual={product.virtual}
                      seller_company={product.seller_company.name}
                      seller_company_id={product.seller_company.id}
                      seller_company_logo={product.seller_company.logo}
                  />
              )))}
          mustAuth={false}
          isAuth={!!token}
          loaderText={loaderText}
          zeroText={<div className='search-result-text'>По запросу "{search}" {contextTitle} ничего не найдено</div>}
      />


          {/*<InfiniteView*/}
          {/*    dataLength={searchItems.length}*/}
          {/*    nextFetch={loadMore}*/}
          {/*    hasMore={hasMore}*/}
          {/*    dataList={(*/}
          {/*        <>*/}
          {/*            { fromContext === searchContext ?*/}
          {/*                <ProductsList products={searchContext === 'products' ? filteredByName : filteredCatByName}*/}
          {/*                              title={filteredCatByName.length !== 0 || searchContext === 'products' ? title() : nothingTitle()} {...props}/> : '' }*/}
          {/*        </>*/}
          {/*    )}*/}
          {/*    mustAuth={false}*/}
          {/*    isAuth={!!token}*/}
          {/*    isLoading={false}*/}
          {/*    loaderText={loaderText}*/}
          {/*    zeroText={<div className='search-result-text'>По запросу "{search}" {contextTitle} ничего не найдено</div>}*/}
          {/*/>*/}
      </div>

  );

}



