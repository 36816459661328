import React from 'react';

import './styles.css';
import {cleanMessage} from "../UserLogin";
import { ReactComponent as UserIcon } from "../../icons/user-icon.svg";
import {Rating} from "semantic-ui-react";
import {BrowserView, MobileView} from "react-device-detect";

export default function Review (props) {
    const {review} = props;
    return (
        <>
            <BrowserView>
                <div className='review-box'>
                    <div className='header-review-box'>
                        <UserIcon className="user-icon-review"/>
                        <div className='text-date-name-review'>
                            <span className='username-review'>{review.reviewer}</span>
                            <span className='date-review'>{review.date_created}</span>
                            <Rating defaultRating={review.rating} disabled maxRating={5} size="large"/>
                        </div>
                    </div>
                    <div className='review-container'>
                        <div className='about-review'>
                            <span className='title-reviews'>Плюсы:</span>
                            <p className='title-text-reviews'>{review.pros}</p>
                        </div>

                        <div className='about-review'>
                            <span className='title-reviews'>Минусы:</span>
                            <p className='title-text-reviews'>{review.cons}</p>
                        </div>

                        <div className='about-review'>
                            <span className='title-reviews'>Отзыв:</span>
                            <p className='title-text-reviews' dangerouslySetInnerHTML={{ __html: review.review }}></p>
                        </div>
                    </div>
                </div>
            </BrowserView>

            <MobileView className="review-mobile">
                    <div className='header-review-box'>
                        <UserIcon className="user-icon-review"/>
                        <div className='text-date-name-review'>
                            <span className='username-review'>{review.reviewer}</span>
                            <span className='date-review'>{review.date_created}</span>
                            <Rating defaultRating={review.rating} disabled maxRating={5} size="large"/>
                        </div>
                    </div>
                    <div className='review-container'>
                        <div className='about-review'>
                            <span className='title-reviews'>Плюсы:</span>
                            <p className='title-text-reviews'>{review.pros}</p>
                        </div>

                        <div className='about-review'>
                            <span className='title-reviews'>Минусы:</span>
                            <p className='title-text-reviews'>{review.cons}</p>
                        </div>

                        <div className='about-review'>
                        <span className='title-reviews'>Отзыв:</span>
                            <p className='title-text-reviews' dangerouslySetInnerHTML={{ __html: review.review }}></p>
                        </div>
                    </div>

            </MobileView>
        </>
    );
}