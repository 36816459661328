import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import './style.css'
import React, {useEffect, useState} from "react";
import ModalImageGallery from "../../components/ModalImageGallery";
import {Image} from "semantic-ui-react";
import ProductPager from "../../components/ProductPager";
import ModalPlayer from "../../components/ModalPlayer";




const Photo = 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg';
const Photo2 = 'https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_3-scaled.jpeg';

export const photos = [
    {
        id: 0,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 1,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 2,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 3,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 4,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 5,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 6,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 7,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 8,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 9,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 10,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 11,
        original: Photo,
        thumbnail: Photo
    },
]

export const videos = [
    {
        id: 0,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 1,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 2,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 3,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 4,
        original: Photo2,
        thumbnail: Photo2
    },
    {
        id: 5,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 6,
        original: Photo,
        thumbnail: Photo
    },
    {
        id: 7,
        original: Photo,
        thumbnail: Photo
    },
]





export function PhotoCard(props) {

    const [modalActive, setModalActive] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

            const modalImageGallery = () => (
                <ModalImageGallery
                    open={modalActive}
                    handleOpen={() => setModalActive(true)}
                    handleClose={() => setModalActive(false)}
                    images={[
                        {
                            original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                            thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                        },
                        {
                            original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                            thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                        },
                        {
                            original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                            thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                        },
                        {
                            original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                            thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                        },
                        {
                            original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                            thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                        },
                        {
                            original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                            thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                        },
                    ]}
                    showAsGallery={false}
                    index={imageIndex}
                />);

                const onImgClick = (index) => {
                    setImageIndex(index);
                    setModalActive(true);
                }

                return (
                <>
                    <BrowserView>
                        <div className="container">
                            <div className='pictures-congress'>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress' onClick={() => (onImgClick(0))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress' onClick={() => (onImgClick(1))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress' onClick={() => (onImgClick(2))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress' onClick={() => (onImgClick(3))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress' onClick={() => (onImgClick(4))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress' onClick={() => (onImgClick(5))}/>
                            </div>
                        </div>
                    </BrowserView>

                    <MobileView>
                        <div className="container mobile">
                            <div className='pictures-congress-gallery-mobile'>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress-gallery-mobile' onClick={() => (onImgClick(0))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress-gallery-mobile' onClick={() => (onImgClick(1))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress-gallery-mobile' onClick={() => (onImgClick(2))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress-gallery-mobile' onClick={() => (onImgClick(3))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress-gallery-mobile' onClick={() => (onImgClick(4))}/>
                                <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                                       className='picture-congress-gallery-mobile' onClick={() => (onImgClick(5))}/>
                            </div>
                        </div>
                    </MobileView>
                    {modalImageGallery()}
                </>
        )
}

export function VideoCard(props) {

    const [modalActive, setModalActive] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const modalImageGallery = () => (
        <ModalImageGallery
            open={modalActive}
            handleOpen={() => setModalActive(true)}
            handleClose={() => setModalActive(false)}
            images={[
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
                {
                    original: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                    thumbnail: "https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg",
                },
            ]}
            showAsGallery={false}
            index={imageIndex}
        />);

        const onImgClick = (index) => {
            setImageIndex(index);
            setModalActive(true);
        }

    return (
        <>
            <BrowserView>
                <div className="container">
                    <div className='pictures-congress'>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(0))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(1))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress' onClick={() => (onImgClick(2))}/>
                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className="container mobile">
                    <div className='pictures-congress-gallery-mobile'>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-gallery-mobile' onClick={() => (onImgClick(0))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-gallery-mobile' onClick={() => (onImgClick(1))}/>
                        <Image src='https://cms.asiaexpo.space/wp-content/uploads/2024/07/opis_kongressa_1-scaled.jpeg'
                               className='picture-congress-gallery-mobile' onClick={() => (onImgClick(2))}/>
                    </div>
                </div>
            </MobileView>
            {modalImageGallery()}
        </>
    )
}

export function Gallery(props) {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [modalActive, setModalActive] = useState(false);
    const [modalIndex, setModalIndex] = useState(1);

    const [modalActiveVideo, setModalActiveVideo] = useState(false);
    const [modalIndexVideo, setModalIndexVideo] = useState(1);

    const showModalVideo = (index) => {
        setModalIndex(index);
        setModalActive(true);
    }

    const showModal = (index) => {
        setModalIndex(index);
        setModalActive(true);
    }

    const arrayPhoto = photos.map((photo) => (
        <>
            <BrowserView>
                <div className='container-pictures-congress'>
                    <div className='pictures-congress'>
                        <img
                            className='picture-congress'
                            id={photo.id}
                            src={photo.original}
                            onClick={() => showModal(photo.id)}
                            alt='Фотография'
                        />

                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className='pictures-congress-gallery-mobile'>
                    <img
                        className='picture-congress-gallery-mobile'
                        id={photo.id}
                        src={photo.original}
                        onClick={() => showModal(photo.id)}
                        alt='Фотография'
                    />

                </div>
            </MobileView>
        </>

    ));

    const arrayVideo = videos.map((video) => (
        <>
            <BrowserView>
                <div className='container-pictures-congress'>
                    <div className='pictures-congress'>
                        <img
                            className='picture-congress'
                            id={video.id}
                            src={video.original}
                            onClick={() => showModal(video.id)}
                            alt='Видео'
                        />

                    </div>
                </div>
            </BrowserView>

            <MobileView>
                <div className='pictures-congress-gallery-mobile'>
                    <img
                        className='picture-congress-gallery-mobile'
                        id={video.id}
                        src={video.original}
                        onClick={() => showModal(video.id)}
                        alt='Видео'
                    />

                </div>
            </MobileView>
        </>
    ));

    return (
        <>
            <BrowserView>
                <div className='main-container congress-online'>
                    <span className='text-congress-online main'>Галерея</span>
                    <br/>
                    <ProductPager
                        itemsPerPage='6'
                        dataLength={photos.length}
                        dataList={arrayPhoto}
                        columns='3'
                        rows='2'
                        width='5'
                    />
                    <ModalImageGallery
                        open={modalActive}
                        handleOpen={() => setModalActive(true)}
                        handleClose={() => setModalActive(false)}
                        images={photos}
                        showAsGallery={false}
                        index={modalIndex}
                    />
                    <br/>
                    <span className='text-congress-online main'>Видео</span>
                    <br/>
                    <ProductPager
                        itemsPerPage='6'
                        dataLength={videos.length}
                        dataList={arrayVideo}
                        columns='3'
                        rows='2'
                        width='5'
                    />
                    <ModalImageGallery
                        open={modalActiveVideo}
                        handleOpen={() => setModalActiveVideo(true)}
                        handleClose={() => setModalActiveVideo(false)}
                        images={videos}
                        showAsGallery={false}
                        index={modalIndexVideo}
                    />
                </div>
            </BrowserView>

            <MobileView>
                <div className='main-container congress-online-mobile-gallery'>
                    <span className='text-congress-online main-mobile'>Галерея</span>
                    <br/>
                    <ProductPager
                        itemsPerPage='4'
                        dataLength={photos.length}
                        dataList={arrayPhoto}
                        columns='2'
                        rows='3'
                        width='8'
                    />
                    <ModalImageGallery
                        open={modalActive}
                        handleOpen={() => setModalActive(true)}
                        handleClose={() => setModalActive(false)}
                        images={photos}
                        showAsGallery={false}
                        index={modalIndex}
                    />
                    <br/>
                    <span className='text-congress-online main-mobile'>Видео</span>
                    <br/>
                    <ProductPager
                        itemsPerPage='4'
                        dataLength={videos.length}
                        dataList={arrayVideo}
                        columns='2'
                        rows='3'
                        width='8'
                    />
                    <ModalImageGallery
                        open={modalActiveVideo}
                        handleOpen={() => setModalActiveVideo(true)}
                        handleClose={() => setModalActiveVideo(false)}
                        images={videos}
                        showAsGallery={false}
                        index={modalIndexVideo}
                    />
                </div>
            </MobileView>
        </>
    )
}