import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import './styles.css';
import {Dimmer, Loader, Dropdown, Modal} from "semantic-ui-react";

import {ReactComponent as CloseIcon} from "../../../icons/close-icon.svg";
import PhoneCountryMask from "../../../components/AddressCard/PhoneCountryMask";
import {putTicket} from "../../../components/UserLogin/actions";
import {
    ADDRESS_COUNTRY,
    ADDRESS_PHONE,
    ADDRESS_PHONE_COUNTRY, fetchCounties, setAddressFields,
} from '../../../components/AddressCard/actions';
import {getAddressFields, getCountries, isCountriesFetching} from '../../../components/AddressCard/reducer';

export default function OfferCard(props) {
    const {category, name, text, date, token, creator, email} = props;
    const dispatch = useDispatch();
    const dateParts = date ? date.split('-') : [];
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];
    const newDate = date ? `${day}.${month}.${year}` : '-';
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        name: '',
        company: '',
        phone: '',
        email: ''
    });

    const [errors, setErrors ] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const isFormValid = errors.nameValue && errors.companyValue && errors.phoneValue && errors.emailValue;
    const [statusButton, setStatusButton] = useState(isFormValid);

    const fields = useSelector((state) => getAddressFields(state.address));
    const isFetching = useSelector((state) => isCountriesFetching(state.address));
    const countries = useSelector((state) => getCountries(state.address));

    useEffect(() => {
        if (!countries || !countries.countries || !countries.countries[fields[ADDRESS_COUNTRY]]) {
            dispatch(fetchCounties());
        }
    }, []);

    useEffect(() => {
        setStatusButton(!isFormValid);
    }, [errors, isFormValid]);

    let listPhoneCountries = [];
    if (!isFetching && countries && countries.countries) {
        listPhoneCountries = Object.values(countries.countries).map((element, index) => (
            {
                key:  index,
                text:  element.country_ru,
                flag: _.lowerCase(element.iso),
                value: element.iso,
                className: "dropdown-item",
                phone_mask: element.phone_mask,
            }
        ));
    }

    const phoneMask = listPhoneCountries.find(item => item.value === formValues.phone_country)?.phone_mask;

    const createEmailTicket = (to, subject, message, email, phone, who) => {
        setIsLoading(true);
        const ticket = {
            subject,
            message,
            email,
            phone,
            who,
            to,
        };
        dispatch(putTicket(token, ticket))
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    function updateField() {
        formValues.phone_country = fields[ADDRESS_PHONE_COUNTRY];
        formValues.phone = fields[ADDRESS_PHONE];
        validate('phone', formValues.phone);
        dispatch(setAddressFields(fields));
    }
    const setDirectField = (name, data) => {
        if (name === 'countryPhoneCode') {
            fields['phone'] = '';
        }
        fields[name] = data;
        updateField();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        validate(name, value);
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const message = `Добрый день ${creator}, ${formValues.name} ${formValues.company} ${formValues.phone} ${formValues.email}, оставил отклик на ваше предложение ${name}`;
        createEmailTicket(email, 'Отклик по вашему предложению', message, formValues.email, formValues.phone, creator);
        setIsFeedbackOpen(false);
    }

    const validate = (fieldName, value) => {
        let fieldErrors = {};
        if (fieldName==='name') {
            if (value.length === 0) {
                fieldErrors.nameValue = false;
            } else {
                fieldErrors.name = '';
                fieldErrors.nameValue = true;
            }
        }
        if (fieldName==='company') {
            if (value.length === 0) {
                fieldErrors.companyValue = false;
            }  else {
                fieldErrors.company = '';
                fieldErrors.companyValue = true;
            }
        }
        if (fieldName==='phone') {
            if (value.length === 0) {
                fieldErrors.phoneValue = false;
            } else {
                fieldErrors.phone = ''
                fieldErrors.phoneValue = true;
            }
        }
        if (fieldName==='email') {
            if (value.length === 0) {
                fieldErrors.emailValue = false;
            } else {
                fieldErrors.email = '';
                fieldErrors.emailValue = true;
            }
        }

        setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors}));
    }

    return (
        <div className='offer-card'>
            <div className='offer-card-header'>
                <div className='offer-card-container'>
                    <p className='offer-card-text'>Действует до {newDate}</p>
                </div>
                <p className='offer-card-category'>{category}</p>
            </div>
            <div>
                <h2 className='offer-card-title'>{name}</h2>
                <p className='offer-card-description'>{text}</p>
            </div>
            <Modal
                className='offers-popup'
                open={isFeedbackOpen}
                trigger={
                    <div className='offer-card-btn-container'>
                        <button className='offer-card-btn' onClick={() => setIsFeedbackOpen(true)}>Откликнуться</button>
                    </div>
                }
                onClose={() => setIsFeedbackOpen(false)}
                centered
            >
                <CloseIcon onClick={() => setIsFeedbackOpen(false)} className="offers-popup-close-icon"/>
                <Modal.Content className="offers-popup-content">
                    <div className="offers-popup-container">
                        <h2 className="offers-popup-title">Заполните данные, чтобы откликнуться на предложение</h2>
                    </div>
                    <form className="offers-popup-form">
                        <input className="offers-popup-input" onChange={handleChange} value={formValues.name} style={{marginBottom: '15px'}} placeholder='ФИО' maxLength={50} name='name'/>
                        <input className="offers-popup-input" onChange={handleChange} value={formValues.company} style={{marginBottom: '15px'}} placeholder='Наименование компании' maxLength={50}
                               name='company'/>
                        <PhoneCountryMask
                            extraClass="offer-popup-phone offers-popup-input"
                            name='phone'
                            countries={listPhoneCountries}
                            loading={isFetching}
                            phone={formValues.phone}
                            phoneMask={phoneMask}
                            handleChangeField={setDirectField}
                            nameCountry={ADDRESS_PHONE_COUNTRY}
                            fluid={true}
                            namePhone={ADDRESS_PHONE}
                        />
                        <input className="offers-popup-input" onChange={handleChange} value={formValues.email} style={{marginBottom: '16px'}} placeholder='Почта' maxLength={50}
                               name='email'/>
                        <button className={'offers-popup-btn ' + (statusButton ? 'offers-popup-btn-disabled' : '')} disabled={statusButton} onClick={handleSubmit} type='submit'>Откликнуться</button>
                    </form>
                </Modal.Content>
            </Modal>
            <Dimmer>
                <Loader active={isLoading} inline='centered' size='medium' content='Загрузка...' />
            </Dimmer>
        </div>
    )
}
