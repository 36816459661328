import React, {useEffect, useState} from 'react';

import {Dropdown, Input, Checkbox, Button, ButtonGroup, FormField, Form} from "semantic-ui-react";
import {useDispatch, useSelector} from "react-redux";
import { getLanguage } from "../LanguageModal/reducer";
import translations from "../LanguageModal/translations";
import {getProducts, getProductsFilter} from "../../views/Products/reducer";
import { ReactComponent as DropdownIcon } from "../../icons/dropdown-icon.svg";
import {
    clearCategoryFilter, clearDisplayOrderFilter,
    clearSellerFilter,
    clearTagFilter,
    setCategoryFilter,
    setDisplayOrderFilter,
    setSellerFilter,
    setTagFilter
} from "../../views/Products/actions";
import {getCategories} from "../../views/Categories/reducer";


// const tagOptions = [
//     { 'key': 1, 'name': "Для коров", id: 1 },
//     { 'key': 2, 'name': "Для коз", id: 2 },
//     { 'key': 3, 'name': "Для кроликов", id: 3 },
//     { 'key': 4, 'name': "Для птиц", id: 4 },
//     { 'key': 5, 'name': "Для коней", id: 5 },
//     { 'key': 6, 'name': "Для овец", id: 6 },
// ];
//
// const categoryOptions = [
//     { key: 1, name: "Категория 1", id: 1 },
//     { key: 2, name: "Категория 2", id: 2 },
//     { key: 3, name: "Категория 3", id: 3 },
// ];

const popularityOptions = [
    { key: 1, name: 'По убыванию', id: 1 },
    { key: 2, name: 'По возрастанию', id: 2 }
];



const SelectedFilters = ({ selectedOptions, handleRemoveFilter }) => {

    return (
        <div className="selected-filters">
            {Object.keys(selectedOptions).map(filterKey => (
                selectedOptions[filterKey].map((option, index) => (
                    <div key={`${filterKey}-${index}`} className="selected-filter-item">
                        <span>{option.name}</span>
                        <button
                            style={{ color: 'white' }}
                            onClick={() => handleRemoveFilter(filterKey, option.id)}
                        >
                            &times;
                        </button>
                    </div>
                ))
            ))}
        </div>
    );
};


const CheckboxDropdown = ({
                              options,
                              selectedOptions,
                              setSelectedOptions,
                              searchTerm,
                              setSearchTerm,
                              type,
                              showMore,
                              setShowMore,
                              onDoneClick,
                              onResetClick,
                          }) => {
    const [inputVisible, setInputVisible] = useState(false);

    const toggleOption = (option) => {
        const updatedOptions = Array.isArray(selectedOptions) ? [...selectedOptions] : [];
        const isSelected = updatedOptions.some(selected => selected.id === option.id);

        if (isSelected) {
            const newOptions = updatedOptions.filter(selected => selected.id !== option.id);
            setSelectedOptions(newOptions);
            onDoneClick(type, newOptions);
        } else {
            const newOptions = [...updatedOptions, option];
            setSelectedOptions(newOptions);
            onDoneClick(type, newOptions);
        }
    };

    const filterOptions = (options) => options.filter(option => option.name.toLowerCase().includes(searchTerm.toLowerCase()));
    // console.log(options.map(option => option.text.toLowerCase().includes(searchTerm.toLowerCase())), '1')
    const visibleOptions = filterOptions(options);
    const displayedOptions = showMore ? visibleOptions : visibleOptions.slice(0, 7);

    return (
        <div className="filter-checkbox-dropdown">
            <Dropdown.Menu onClick={(e) => e.stopPropagation()}>
                {inputVisible && (
                    <Dropdown.Item key="search" className="filter-search-input">
                        <Input
                            icon="search"
                            placeholder="Поиск..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Dropdown.Item>
                )}
                    {displayedOptions.map(option => (
                        <Dropdown.Item key={option.key}>
                            <Checkbox
                                label={option.name}
                                checked={selectedOptions.some(selected => selected.id === option.id)}
                                onChange={() => toggleOption(option)}
                            />
                        </Dropdown.Item>
                    ))}
                {visibleOptions.length > 7 && ( !showMore ? (
                        <Dropdown.Item key="show-more" onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(true);
                            setInputVisible(true);
                        }}>
                            <Button className="show-more-button">Показать ещё</Button>
                        </Dropdown.Item>
                    ) : (
                        <Dropdown.Item key="show-less" onClick={(e) => {
                            e.stopPropagation();
                            setShowMore(false);
                            setInputVisible(false);
                        }}>
                            <Button className="show-more-button">Показать меньше</Button>
                        </Dropdown.Item>
                    )
                )}
                    <ButtonGroup className='filter-buttons-done'>
                        <Button className='filter-button-done' onClick={onResetClick}>Сбросить</Button>
                        {/*<Button className='filter-button-done' onClick={() => onDoneClick(type, selectedOptions)}>Готово</Button>*/}
                    </ButtonGroup>
                </Dropdown.Menu>
            </div>
    );
};

export default function FilterDropdownMenu(props) {

    const { isOffers, isCompany, filter, routeAction, isPageReloaded, isFilterVisible = false} = props;
    const dispatch = useDispatch();

    const language = useSelector(getLanguage);
    const products = useSelector((state) => getProducts(state.products));
    const filterProducts = useSelector((state) => getProductsFilter(state.products));
    const categories = useSelector((state) => getCategories(state.categories));

    const allSellers = products.flatMap(product => product.seller_company || []);
    const uniqueSellers = Array.from(new Map(allSellers.filter(seller => seller && seller.id).map(seller => [seller.id, seller])).values());

    const prodFilter = filter ?? {
        tags: filterProducts.tag ?? [],
        sellers: filterProducts.seller ?? [],
        categories: filterProducts.category ?? [],
        popularities: filterProducts.popularity ?? []
    }

    // console.log("filter, category", filterProducts, categories);

    const sellerOptions = uniqueSellers.map(seller => ({
        key: seller.id,
        name: seller.name,
        id: seller.id,
    }));

    const allTags = products.flatMap(product => product.tags)
    const tagOptions = Array.from(new Map(allTags.filter(cat => cat && cat.id).map(c => [c.id, c])).values());


    const categoryOptions = categories.map(cat => ({
        name: cat.name,
        key: cat.term_id,
        id: cat.term_id,
    }))

    // const categoryIds = categories.map(category => category.term_id);
    // const filteredCategoryOptions = categoryOptions.filter(option => !categoryIds.includes(option.id));

    // console.log("Filtered category options:", filteredCategoryOptions);


    const [showMore, setShowMore] = useState({
        seller: false,
        tag: false,
        category: false,
        popularity: false
    });

    const [activeDropdown, setActiveDropdown] = useState(null);

    const t = (key) => {
        const languageString = language[0] + language[1];
        const translation = translations[languageString];
        return translation ? translation[key] : key;
    };

    const closeDropdown = () => {
        setActiveDropdown(null);
    };

    const [searchTermTag, setSearchTermTag] = useState('');
    const [searchTermSeller, setSearchTermSeller] = useState('');
    const [searchTermCategory, setSearchTermCategory] = useState('');
    const [searchTermPopularity, setSearchTermPopularity] = useState('');

    const selectedTag = Array.isArray(prodFilter.tags) ? tagOptions.filter(option => prodFilter.tags.includes(option.id)) : [];
    const selectedSeller = Array.isArray(prodFilter.sellers) ? sellerOptions.filter(option => prodFilter.sellers.includes(option.id)) : [];
    const selectedCategory = Array.isArray(prodFilter.categories) ? categoryOptions.filter(option => prodFilter.categories.includes(option.id)) : [];
    const selectedPopularity = Array.isArray(prodFilter.popularities) ? popularityOptions.filter(option => prodFilter.popularities.includes(option.id)) : [];

    const [selectedOptions, setSelectedOptions] = useState({
        tag: selectedTag,
        seller: selectedSeller,
        category: selectedCategory,
        popularity: selectedPopularity
    });

    // useEffect(() => {
    //     if (filter && routeAction === 'PUSH') {
    //         dispatch(setTagFilter([]))
    //         dispatch(setCategoryFilter([]))
    //         dispatch(setDisplayOrderFilter([]))
    //         dispatch(setSellerFilter([]))
    //     }
    // }, []);

    const allSelectedOptions = Object.values(selectedOptions).reduce((count, options) => count + options.length, 0);

    const handleDoneClick = (type, selectedOptions) => {
        setSelectedOptions(prev => ({ ...prev, [type]: selectedOptions }));
        if (type === "tag") {
            dispatch(setTagFilter(selectedOptions.map(option => option.id)));
        }
        else if (type === "seller") {
            dispatch(setSellerFilter(selectedOptions.map(option => option.id)));
        }
        else if (type === "category") {
            dispatch(setCategoryFilter(selectedOptions.map(option => option.id)));
        }
        else if (type === "popularity") {
            dispatch(setDisplayOrderFilter(selectedOptions.map(option => option.id)));
        }
    };

    const handleResetFilters = (type) => {
        setSelectedOptions(prev => ({ ...prev, [type]: [] }));
        if (type === "tag") {
            dispatch(setTagFilter([]));
        }
        else if (type === "seller") {
            dispatch(setSellerFilter([]));
        }
        else if (type === "category") {
            dispatch(setCategoryFilter([]));
        }
        else if (type === "popularity") {
            dispatch(setDisplayOrderFilter([]));
        }
    };

    const handleRemoveFilter = (type, value) => {
        setSelectedOptions(prevSelectedOptions => ({
            ...prevSelectedOptions,
            [type]: prevSelectedOptions[type].filter(option => option.id !== value)
        }));

        if (type === "tag") {
            dispatch(clearTagFilter([value]));
        }
        else if (type === "seller") {
            dispatch(clearSellerFilter([value]));
        }
        else if (type === "category") {
            dispatch(clearCategoryFilter([value]));
        }
        else if (type === "popularity") {
            dispatch(clearDisplayOrderFilter([value]));
        }
    };



    const handleRemoveAllFilters = () => {
        setSelectedOptions({
            tag: [],
            seller: [],
            category: [],
            popularity: []
        })

        dispatch(setTagFilter([]));
        dispatch(setSellerFilter([]));
        dispatch(setCategoryFilter([]));
        dispatch(setDisplayOrderFilter([]));
    }


    return (
        isFilterVisible ? <div className="filtration">
            <Dropdown
                className="filtration-button"
                text="Метки"
                icon={<DropdownIcon className="dropdown-icon" />}
                onClick={() => setActiveDropdown(activeDropdown === "tag" ? null : "tag")}
                open={activeDropdown === "tag"}
            >
                <CheckboxDropdown
                    options={tagOptions}
                    selectedOptions={selectedOptions.tag}
                    setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, tag: newOptions }))}
                    searchTerm={searchTermTag}
                    setSearchTerm={setSearchTermTag}
                    type="tag"
                    showMore={showMore}
                    setShowMore={setShowMore}
                    onDoneClick={handleDoneClick}
                    onResetClick={() => handleResetFilters('tag')}
                />
            </Dropdown>
            {isCompany && (
                <Dropdown
                    className="filtration-button"
                    text="Продавец"
                    icon={<DropdownIcon className="dropdown-icon" />}
                    onClick={() => setActiveDropdown(activeDropdown === "seller" ? null : "seller")}
                    open={activeDropdown === "seller"}
                >
                    <CheckboxDropdown
                        options={sellerOptions}
                        selectedOptions={selectedOptions.seller}
                        setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, seller: newOptions }))}
                        searchTerm={searchTermSeller}
                        setSearchTerm={setSearchTermSeller}
                        type="seller"
                        showMore={showMore.seller}
                        setShowMore={(val) => setShowMore(prev => ({ ...prev, seller: val }))}
                        onCloseDropdown={closeDropdown}
                        onDoneClick={handleDoneClick}
                        onResetClick={() => handleResetFilters('seller')}
                    />
                </Dropdown>

            )}
            <Dropdown
                className="filtration-button"
                text={t("category")}
                icon={<DropdownIcon className="dropdown-icon" />}
                onClick={() => setActiveDropdown(activeDropdown === "category" ? null : "category")}
                open={activeDropdown === "category"}
            >
                <CheckboxDropdown
                    options={categoryOptions}
                    selectedOptions={selectedOptions.category}
                    setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, category: newOptions }))}
                    searchTerm={searchTermCategory}
                    setSearchTerm={setSearchTermCategory}
                    type="category"
                    showMore={showMore.category}
                    setShowMore={(val) => setShowMore(prev => ({ ...prev, category: val }))}
                    onCloseDropdown={closeDropdown}
                    onDoneClick={handleDoneClick}
                    onResetClick={() => handleResetFilters('category')}
                />
            </Dropdown>
                <Dropdown
                    className="filtration-button"
                    text={"Порядок отображения"}
                    icon={<DropdownIcon className="dropdown-icon" />}
                    onClick={() => setActiveDropdown(activeDropdown === "popularity" ? null : "popularity")}
                    open={activeDropdown === "popularity"}
                >
                    <CheckboxDropdown
                        options={popularityOptions}
                        selectedOptions={selectedOptions.popularity}
                        setSelectedOptions={(newOptions) => setSelectedOptions(prev => ({ ...prev, popularity: newOptions }))}
                        searchTerm={searchTermPopularity}
                        setSearchTerm={setSearchTermPopularity}
                        type="popularity"
                        showMore={showMore.popularity}
                        setShowMore={(val) => setShowMore(prev => ({ ...prev, popularity: val }))}
                        onCloseDropdown={closeDropdown}
                        onDoneClick={handleDoneClick}
                        onResetClick={() => handleResetFilters('popularity')}
                    />
                </Dropdown>
            <SelectedFilters
                selectedOptions={selectedOptions}
                handleRemoveFilter={handleRemoveFilter}
            />

            {allSelectedOptions > 1 && (
                <Button onClick={handleRemoveAllFilters} className="clear-filter-button">
                    Убрать все фильтры
                </Button>
            )}
        </div> : null
    );
};

