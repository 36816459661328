import React, {useEffect, useState} from 'react';
import './styles.css';
import {useSelector} from "react-redux";
import {Dropdown, Modal} from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';

import { isMobile, isBrowser } from 'react-device-detect';
import FilterModal from "../../components/Filtration/filter";
import FilterDropdownMenu from "../../components/Filtration/dropdownMenu";
import {getOffers, getOffersFetching} from "./reducer";
import {createCustomerOffer} from "./actions";
import OfferCard from "./OfferCard";
import {getUserData} from "../../components/UserLogin/reducer";
import {ReactComponent as CloseIcon} from "../../icons/close-icon.svg";
import {ReactComponent as DropdownIcon} from "../../icons/dropdown-icon.svg";
import InfiniteView from "../../components/InfiniteView";


export default function CustomerOffers(props) {
    const { token, dispatch } = props;
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const userData = useSelector((state) => getUserData(state.userLogin));
    console.log(userData)
    const offersStage = useSelector((state) => getOffers(state.offers));
    const date = new Date()
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const endDate = (date.getFullYear() + '-' + month + '-' + day);
    const [formValues, setFormValues] = useState({});
    const [offers, setOffers] = useState([]);
    const arrayCategories = [
        {key: 1, text: 'Растениеводство', value: 'Растениеводство'},
        {key: 2, text: 'Животноводство', value: 'Животноводство'},
        {key: 3, text: 'Техника', value: 'Техника'},
        {key: 4, text: 'Услуги', value: 'Услуги'},
        {key: 5, text: 'Продукты производство', value: 'Продукты производство'},
    ];

    const [errors, setErrors ] = useState({});

    const isFormValid = errors.categoryValue && errors.nameValue && errors.descValue && errors.dateValue;
    const [statusButton, setStatusButton] = useState(isFormValid);

    useEffect(() => {
        const arrayCards = [];
        offersStage.forEach((offer) => {
            arrayCards.push(
                <OfferCard
                    category={offer.category}
                    name={offer.name}
                    text={offer.description}
                    date={offer.date}
                    token={token}
                    creator={offer.creator}
                    email={offer.email}
                />
            )
        })
        setOffers(arrayCards);
    }, [offersStage]);

    useEffect(() => {
        setStatusButton(!isFormValid);
        console.log(errors);
    }, [errors, isFormValid]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        validate(name, value);
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    const handleChangeCategory = (e, { value }) => {
        validate('category', value);
        setFormValues({
            ...formValues,
            category: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const offer = {
            ...formValues,
            email: userData.billing.email,
            creator: userData.billing.last_name + ' ' + userData.billing.first_name,
        }
        dispatch(createCustomerOffer(offer));
        setIsCreateOpen(false);
        setFormValues({
            category: '',
            name: '',
            description: ''
        })
        setErrors({})

    }

    const validate = (fieldName, value) => {
        let fieldErrors = {};
        if (fieldName==='category') {
            if (value.length === 0) {
                fieldErrors.categoryValue = false;
            } else {
                fieldErrors.category = '';
                fieldErrors.categoryValue = true;
            }
        }
        if (fieldName==='name') {
            if (value.length === 0) {
                fieldErrors.nameValue = false;
            } else if (value.length === 50) {
                fieldErrors.name = 'Достигнут лимит символов';
                fieldErrors.nameValue = true;
            } else {
                fieldErrors.name = '';
                fieldErrors.nameValue = true;
            }
        }
        if (fieldName==='description') {
            if (value.length === 0) {
                fieldErrors.descValue = false;
            } else if (value.length === 300) {
                fieldErrors.description = 'Достигнут лимит символов';
                fieldErrors.descValue = true;
            } else {
                fieldErrors.description = ''
                fieldErrors.descValue = true;
            }
        }
        if (fieldName==='date') {
            if (value.length === 0) {
                fieldErrors.dateValue = false;
            } else {
                fieldErrors.date = ''
                fieldErrors.dateValue = true;
            }
        }
        setErrors((prevErrors) => ({ ...prevErrors, ...fieldErrors}));
    }

    return (
        <div className="offers-view">
            <h1 className='offers-title'>Предложения покупателей</h1>
            <Modal
                className='offers-popup'
                open={isCreateOpen}
                trigger={
                    <button className='offers-btn' onClick={() => setIsCreateOpen(true)}>
                        Опубликовать предложение
                    </button>
                }
                onClose={() => setIsCreateOpen(false)}
                centered
            >
                <CloseIcon onClick={() => setIsCreateOpen(false)} className="offers-popup-close-icon"/>
                <Modal.Content className="offers-popup-content">
                    <div className="offers-popup-container">
                        <h2 className="offers-popup-title">Заполните данные для дальнейшей публикации</h2>
                        <span className="offers-popup-text">*Предложение должно касаться данной площадки</span>
                    </div>
                    <form className="offers-popup-form" onSubmit={handleSubmit}>
                        <Dropdown
                            className="offers-popup-input"
                            placeholder='Категория товара'
                            options={arrayCategories}
                            id="offers-input-1"
                            icon={<DropdownIcon className="offers-popup-dropdown-icon"/>}
                            onChange={handleChangeCategory}
                            value={formValues.category}
                            name='category'
                            fluid
                            search
                        />
                        <input className="offers-popup-input"  placeholder='Наименование позиции' onChange={handleChange} onPaste={handleChange} maxLength={50} style={errors.name ? {marginBottom: '0px'} : {}} value={formValues.name} name='name' id="offers-input-2"/>
                        <span className="offers-popup-error">{errors.name}</span>
                        <textarea className="offers-popup-input" name='description' placeholder='Текст предложения' onChange={handleChange}  style={errors.description ? {marginBottom: '0px'} : {}}maxLength={300} value={formValues.description} id="offers-input-3"/>
                        <span className="offers-popup-error">{errors.description}</span>
                        <input className="offers-popup-input" min={endDate} max='2030-01-01' type='text' onFocus={(e) => e.target.type = 'date'} name='date' placeholder='Укажите срок действия' onChange={handleChange} value={formValues.date} id="offers-input-4"/>
                        <button disabled={statusButton} className={('offers-popup-btn ') + (statusButton ? 'offers-popup-btn-disabled' : '')} type='submit'>Оставить заявку</button>
                    </form>
                </Modal.Content>
            </Modal>
                {isMobile ?
                    <div className='offers-filter-modal'>
                        <FilterModal  isMobile={true}  isOffers={true} />
                    </div>
                    :
                    <div className='offers-filter-dropdown'>
                        <FilterDropdownMenu isOffers={true}/>
                    </div>
                }
            <div className='offers-container'>
                {offers}
            </div>
        </div>
    )
}
