import { combineReducers } from 'redux';

import {REQUEST_REVIEWS, RECEIVE_REVIEWS, REVIEWS_PAGE_COUNT} from './actions';
import {mergeObjectArrays} from "../../configureStore";

const items = (state = [], action) => {
  switch (action.type) {
    case REQUEST_REVIEWS:
      return state;
    case RECEIVE_REVIEWS:
      return action.page === 1? action.reviews : mergeObjectArrays(action.reviews, state);
    default:
      return state;
  }
};

const isFetching = (state = 0, action) => {
  switch (action.type) {
    case REQUEST_REVIEWS:
      return true;
    case RECEIVE_REVIEWS:
      return false;
    default:
      return state;
  }
};

const hasMore = (state = false, action) => {
  switch (action.type) {
    case REQUEST_REVIEWS:
      return true;
    case RECEIVE_REVIEWS:
      return action.reviews.length >= REVIEWS_PAGE_COUNT;
    default:
      return state;
  }
};

export const getReviews = (state, productId) => state.items.filter(obj => String(obj.product_id) === String(productId));
export const getReviewsFetching = state => state.isFetching;
export const getReviewsHasMore = state => state.hasMore;

export default combineReducers({
  items,
  isFetching,
  hasMore
});
