import config, {AUTH_INVALID_MESSAGE, AUTH_INVALID_TOKEN, SERVICE_LOGICAL_ACCESS_DENIED} from "../../config/config";
import {dropToken} from "../../components/UserLogin/actions";
import {toastr} from "react-redux-toastr";

export const REQUEST_NEWS = 'REQUEST_NEWS';
export const RECEIVE_NEWS = 'RECEIVE_NEWS';

export const requestNews = () => ({
    type: 'REQUEST_NEWS',
})

export const receiveNews = (news, page) => ({
    type: 'RECEIVE_NEWS',
    news,
    page,
})

export const NEWS_PAGE_COUNT = 20;

const process_api_error = (json, dispatch) => {
    dispatch(receiveNews([]));
    if (json.code === AUTH_INVALID_TOKEN) {
        dispatch(dropToken());
        toastr.error(AUTH_INVALID_MESSAGE);
    } else
    if (json.code === SERVICE_LOGICAL_ACCESS_DENIED)
        toastr.error(json.message);
    else
        toastr.error('Ошибка сервера: ' + String(json.code) + '. ' + json.message);
};


export const fetchNews = (params = {}) => (dispatch) => {
    dispatch(requestNews(params.page ?? 1));

    let url = config.API_NEWS_URL;
    if (params)
        url += '?'
            + Object.keys(params)
                .map((k) => k + '=' + encodeURIComponent(params[k]))
                .join('&');

    return fetch(url)
        .then((response) => response.json())
        .then((json) => {
            if (json.code === 200) {
                dispatch(receiveNews(json.data, params.page ?? 1));
            } else {
                process_api_error(json, dispatch);
            }
        })
        .catch((error) => {
            // toastr.error('Ошибка запроса', String(error));
            dispatch(receiveNews([]));
        });
}